<template>
	<div class="track_list">
		<div class="head_tracks_list">
			<div class="_left_">
				<div class="title_" :class="{hide_like: UPPER_FILTER === 'favorite'}">Title
					<div class="sort">
						<span class="material-icons" :class="{active: currentOrderBy === 'order[title]_asc'}"
						      @click="orderBy('title', 'asc')">arrow_drop_up</span>
						<span class="material-icons" :class="{active: currentOrderBy === 'order[title]_desc'}"
						      @click="orderBy('title', 'desc')">arrow_drop_down</span>
					</div>
				</div>
				<div class="artist_">Artist
					<div class="sort">
						<span class="material-icons" :class="{active: currentOrderBy === 'order[artist.name]_asc'}" @click="orderBy('artist.name', 'asc')">arrow_drop_up</span>
						<span class="material-icons" :class="{active: currentOrderBy === 'order[artist.name]_desc'}" @click="orderBy('artist.name', 'desc')">arrow_drop_down</span>
					</div>
				</div>
				<div class="released_" v-if="SHOW_COLUMNS_TRACK.includes('released')">Released
					<div class="sort">
						<span class="material-icons" :class="{active: currentOrderBy === 'order[releasedAt]_asc'}" @click="orderBy('releasedAt', 'asc')">arrow_drop_up</span>
						<span class="material-icons" :class="{active: currentOrderBy === 'order[releasedAt]_desc'}" @click="orderBy('releasedAt', 'desc')">arrow_drop_down</span>
					</div>
				</div>
				<div class="bpm_" v-if="SHOW_COLUMNS_TRACK.includes('bpm')">BPM
					<div class="sort">
						<span class="material-icons" :class="{active: currentOrderBy === 'order[bpm]_asc'}" @click="orderBy('bpm', 'asc')">arrow_drop_up</span>
						<span class="material-icons" :class="{active: currentOrderBy === 'order[bpm]_desc'}"
						      @click="orderBy('bpm', 'desc')">arrow_drop_down</span>
					</div>
				</div>
				<div class="key_" v-if="SHOW_COLUMNS_TRACK.includes('key')">KEY
					<div class="sort">
						<span class="material-icons" :class="{active: currentOrderBy === 'order[key]_asc'}" @click="orderBy('key', 'asc')">arrow_drop_up</span>
						<span class="material-icons" :class="{active: currentOrderBy === 'order[key]_desc'}"
						      @click="orderBy('key', 'desc')">arrow_drop_down</span>
					</div>
				</div>
			</div>


			<div class="_right_ actions_">
				<v-menu
					transition="slide-y-transition"
					:close-on-content-click="false"
					:nudge-width="120"
					content-class="menu-pointer"
					offset-y
					nudge-left="60"
					nudge-bottom="15"
					origin="top center">
					<template v-slot:activator="{ on, attrs }">
						<span class="material-icons" v-bind="attrs" v-on="on">arrow_drop_down</span>
					</template>
					<v-list>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="label" value="label" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="label">Label</label>
							</div>
						</v-list-item>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="released" value="released" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="released">Released</label>
							</div>
						</v-list-item>

						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="viewes" value="viewes" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="viewes">Viewes</label>
							</div>
						</v-list-item>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="downloads" value="downloads" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="downloads">Downloads</label>
							</div>
						</v-list-item>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="collected" value="collected" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="collected">Collected</label>
							</div>
						</v-list-item>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="bpm" value="bpm" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="bpm">BPM</label>
							</div>
						</v-list-item>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="key" value="key" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="key">Key</label>
							</div>
						</v-list-item>

					</v-list>
				</v-menu>
			</div>
		</div>

		<template v-if="UPPER_FILTER !== 'popular' && UPPER_FILTER !== 'trending'">

			<template v-if="UPPER_FILTER !== 'favorite'">
				<template v-if="TRACKS_LOADING === true">
					<v-skeleton-loader
						type="image"
						class="item_track_list"
						v-for="index in 25"
						:key="index"
					></v-skeleton-loader>
				</template>
				<template v-if="TRACKS_LOADING === false">
					<template v-if="TRACKS.length">
						<itemTrack
							v-for="(track, index) in TRACKS"
							:key="+track.id"
							:track="track"
							:playList="'defaultTrackList'"
							:index="index">
						</itemTrack>
						<pagination v-if="COUNT_TRACKS > 30"
						            :count="COUNT_TRACKS"
						            :currentPage="TRACKS_CURRENT_PAGE"
						            :commit="'SET_TRACKS_CURRENT_PAGE'">
						</pagination>
					</template>
					<nothingHereYet v-if="!TRACKS.length"></nothingHereYet>
				</template>
			</template>

			<template v-if="UPPER_FILTER === 'favorite'">
				<template v-if="FAVORITE_TRACKS_LOADING === true">
					<v-skeleton-loader
						type="image"
						class="item_track_list"
						v-for="index in 25"
						:key="index"
					></v-skeleton-loader>
				</template>
				<template v-if="FAVORITE_TRACKS_LOADING === false">
					<template v-if="FAVOURITE_TRACKS.length">
						<itemTrack
							v-for="(track, index) in FAVOURITE_TRACKS"
							:key="track.id"
							:track="track.song"
							:hideLike="false"
							:playList="'favoriteTrackList'"
							:index="index">
						</itemTrack>
						<pagination v-if="COUNT_FAVOURITE_TRACKS > 30"
						            :count="COUNT_FAVOURITE_TRACKS"
						            :currentPage="FAVORITE_TRACKS_CURRENT_PAGE"
						            :commit="'SET_FAVORITE_TRACKS_CURRENT_PAGE'">
						</pagination>
					</template>
					<nothingHereYet v-if="!FAVOURITE_TRACKS.length"></nothingHereYet>
				</template>
			</template>
		</template>
		<nothingHereYet v-if="UPPER_FILTER === 'popular' || UPPER_FILTER === 'trending'"></nothingHereYet>

	</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import itemTrack from "@/components/musicReleases/itemTrack";
import pagination from "@/components/pagination";
import nothingHereYet from "@/components/small/nothingHereYet";

import eventBus from "@/eventBus";

import isMobile from "@/mixins/isMobile";

export default {
	name: 'trackList',
	components: {
		itemTrack,
		pagination,
		nothingHereYet
	},
	data() {
		return {
			dynamicTrackKey: 0,
			currentOrderBy: `order[bpm]: asc`,
			tracksColumns: this.$store.getters.SHOW_COLUMNS_TRACK,
			tracks: [],
		}
	},
	mixins: [isMobile],
	watch: {
		UPPER_FILTER() {
			console.log('watch tracks UPPER_FILTER',);
			this.getTracks();
		},
		SUBGENRE() {
			console.log('watch tracks SUBGENRE',);
			this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
			this.getTracks();
		},
		BPM() {
			console.log('watch tracks bpm',);
			this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
			this.getTracks();
		},
		APPLY_KEYS() {
			this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
			this.$store.commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1);
			console.log('watch tracks apply keys');
			this.getTracks();
		},
		TAGS() {
			console.log('watch tracks TAGS',);
			this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
			this.getTracks();
		},
		TRACKS_ORDER_BY() {
			console.log('trackList.vue watch tracks TRACKS_ORDER_BY', this.TRACKS_ORDER_BY);
			this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
			this.getTracks();
		},
		TRACKS_CURRENT_PAGE() {
			console.log('watch tracks TRACKS_CURRENT_PAGE',);
			this.getTracks();
			this.scrollTop();
		},
		FAVORITE_TRACKS_CURRENT_PAGE() {
			console.log('watch tracks FAVORITE_TRACKS_CURRENT_PAGE',);
			this.getTracks();
			this.scrollTop();
		},
		SEARCH_STRING() {
			console.log('watch tracks SEARCH_STRING',);
			this.getTracks();
		}
	},
	computed: {
		...mapGetters([
			'TRACKS', 'COUNT_TRACKS', 'TRACKS_CURRENT_PAGE', 'TRACKS_LOADING', 'TRACKS_ORDER_BY', 'SHOW_COLUMNS_TRACK',
			'FAVOURITE_TRACKS', 'COUNT_FAVOURITE_TRACKS', 'FAVORITE_TRACKS_CURRENT_PAGE', 'FAVORITE_TRACKS_LOADING',
			'UPPER_FILTER', 'SUBGENRE', 'KEYS', 'APPLY_KEYS', 'BPM', 'TAGS', 'SEARCH_STRING'
		])
	},
	mounted() {
		if (!this.TRACKS.length && this.UPPER_FILTER !== 'favorite') {
			console.log('this.getTracks - 1');
			this.getTracks();
		}
		if (this.UPPER_FILTER === 'favorite' && !this.FAVOURITE_TRACKS.length) {
			console.log('this.getTracks - 2');
			this.getTracks();
		}

    eventBus.on('customEvent', this.getTracks);
	},
	methods: {
		...mapActions(['GET_TRACKS', 'GET_FAVORITE_TRACKS']),
		scrollTop() {
			this.$nextTick(() => {
				const el = document.getElementById('explore-nav-id');
				const el2 = document.getElementById('explore-albums-nav-id');
				const el3 = document.getElementById('mobile-filter-bar-id');
				if(this.mobile){
					if(el3 && el2){
					el2.scrollIntoView({behavior: 'smooth'});
					} else if(el3){
					el3.scrollIntoView({behavior: 'smooth'});
					}
				} else {
					if (el) {
					el.scrollIntoView({behavior: 'smooth'});
				} 
				}
			});
		},

		orderBy: function (key, direction) {
			console.log('run trackList.vue orderBy');
			this.currentOrderBy = `order[${key}]_${direction}`;

			let isFavoriteTracks = ``;
			if (this.UPPER_FILTER === 'favorite') {
				isFavoriteTracks = `song.`;
			}

			this.$store.dispatch('UPDATE_TRACKS_ORDER_BY', {[`order[${isFavoriteTracks}${key}]`]: `${direction}`});
		},

		getTracks() {
			console.log('get tracks - trackList');
			this.$store.commit('SET_TRACK_SCROLL_ID', null);
			const subGenre = this.SUBGENRE.id ? {'playLists.musicRelease.subGenres.id': this.SUBGENRE.id} : ``;
			const favoriteSubGenre = this.SUBGENRE.id ? {'musicRelease.subGenres.id': this.SUBGENRE.id} : ``;
			const bpm = this.BPM.length ? {'bpm[between]': `${this.BPM[0]}` + '..' + `${this.BPM[1]}`} : null;

			let keys;
			if (this.APPLY_KEYS && Array.isArray(this.APPLY_KEYS)) {
          const keyName = (this.UPPER_FILTER === 'favorite') ? 'song.key' : 'key';
          keys = this.APPLY_KEYS.reduce((acc, key, index) => {
              acc[`${keyName}[${index + 1}]`] = key.id;
              return acc;
          }, {});
			}

			const tags = this.TAGS.length ? {'multiple_search_playLists.album.tags': Array(this.TAGS)} : null;
			const page = {page: this.TRACKS_CURRENT_PAGE};

			// favorite tracks params
			const pageFavorite = {page: this.FAVORITE_TRACKS_CURRENT_PAGE};
			const bpmFavorite = this.BPM.length ? {'song.bpm[between]': `${this.BPM[0]}` + '..' + `${this.BPM[1]}`} : null;

			let params;

			switch (this.UPPER_FILTER) {
				case 'pending':
					params = Object.assign({'status': this.ALBUM_STATUS}, subGenre, bpm, tags, page, this.TRACKS_ORDER_BY);
					break;
				case 'undiscovered':
					params = Object.assign(subGenre, bpm, keys, tags, page, this.TRACKS_ORDER_BY);
					break;
				case 'explore':
					params = Object.assign(subGenre, bpm, keys, tags, page, this.TRACKS_ORDER_BY);
					break;
				// case 'popular':
				//     params = Object.assign({'status': 'none'}, subGenre, tags, limit, page);
				//     break;
				// case 'trending':
				//     params = Object.assign({'status': 'none'}, subGenre, tags, limit, page);
				//     break;
				case 'favorite':
					params = Object.assign(favoriteSubGenre, bpmFavorite, keys, tags, pageFavorite, this.TRACKS_ORDER_BY); // , {isPurchased: true}
					break;
			}
            console.log('keys', keys);
            if (this.UPPER_FILTER === 'pending' || this.UPPER_FILTER === 'undiscovered' || this.UPPER_FILTER === 'explore') {
				this.GET_TRACKS(params)
					.then(() => {
						this.dynamicTrackKey += 1;
					})
					.catch(err => {
						console.log(`getTracks, ${err}`);
					})
					.finally(() => {
					});
			}
			if (this.UPPER_FILTER === 'favorite') {
				this.GET_FAVORITE_TRACKS(params)
					.then(() => {

					})
					.catch(err => {
						console.log(`getFavouriteTracks, ${err}`);
					})
					.finally(() => {
					})
			}
		},

		checkTrackColumn() {
			this.$store.commit('SET_SHOW_COLUMNS_TRACK', this.tracksColumns);
		},
	}
}
</script>

<style lang="scss">
.track_list {
	.item_track_list {
		height: 50px;
		margin-bottom: 6px;
		border-radius: 50px;
	}
}
</style>
